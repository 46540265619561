import {
  CloseIcon,
  DeleteIcon,
  EditIcon,
  InfoOutlineIcon,
} from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/store';

const AdminFileList = () => {
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedPartnerName, setSelectedPartnerName] = useState(null);
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState<any | null>(null);
  const [options, setOptions] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState('');
  const [view, setView] = useState<'approved' | 'pending'>('approved');
  const toast = useToast();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { login, getToken } = useAuth();
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [showtitleTextbox, setShowTitleTextbox] = useState(false);
  const [title, setTitle] = useState('');
  const [showTextbox, setShowTextbox] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [showURLTextbox, setShowURLTextbox] = useState(false);
  const [sourceURL, setSourceURL] = useState('');
  const inputTextColor = useColorModeValue('black', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editedFileName, setEditedFileName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [fileToEdit, setFileToEdit] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSave = async () => {
    if (!editedFileName.trim()) {
      // Check if the name is empty or contains only whitespace
      toast({
        title: 'Error',
        description: 'File name cannot be empty. Please provide a valid name.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      return; // Exit the function if the name is invalid
    }
    setIsSaving(true);

    try {
      const token = await getToken(); // Assuming you have a getToken function

      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-file-name`, // Replace with your actual API endpoint
        {
          partner: selectedPartnerName!,
          persona: selectedPersona.name,
          filename: fileToEdit.file_name,
          document_id: fileToEdit.id,
          newname: editedFileName,
          auth: profile?.user?.sub, // Assuming you're sending user authorization
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast({
          title: 'Update Successful',
          description: 'The file name has been successfully updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        onClose();
      }
    } catch (error) {
      console.error('Error updating file name:', error);
      toast({
        title: 'Error',
        description:
          'An error occurred while updating the file name. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setIsSaving(false);
      setFileToEdit(null);
      authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
      setDisplayName('');
      setShowTextbox(false);
      setSourceURL('');
      setShowURLTextbox(false);
      setTitle('');
      setShowTitleTextbox(false);
    }
  };

  const handleEditFileName = (editedFile: any) => {
    setFileToEdit(editedFile);
    onOpen();
  };

  // Function to fetch partners from the API
  const fetchPartners = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-partners`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      const filteredPartners = response.data.filter(
        (partner: any) =>
          partner.name === 'state_banking_compliance' ||
          partner.name === 'federal_banking_compliance'
      );
      setPartners(filteredPartners);
    } catch (error) {
      console.error('Error fetching partners:', error);
      toast({
        title: 'Error',
        description: 'There was an error fetching partners.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  // Function to fetch personas based on selected partner
  const fetchPersonas = async (partnerId: number) => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_LLM_API_URL}/get-personas/${partnerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );
      setPersonas(response.data);
    } catch (error) {
      console.error('Error fetching personas:', error);
      toast({
        title: 'Error',
        description: 'There was an error fetching personas.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  // Function to authorize and fetch options based on partner and persona
  const authorizeAndGetOptions = async (
    partnerName: string,
    personaName: string
  ) => {
    setLoadingOptions(true);
    try {
      const token = await getToken();
      const apiResponse = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/get-all-files?partner=${partnerName}&persona=${personaName}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Check if filenames exist in the response
      if (!apiResponse.data.filenames || !apiResponse.data.filenames.length) {
        toast({
          title: 'No Files Found',
          description:
            'No files were found for the selected partner and persona.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
        return; // Exit the function without setting any data
      }

      const filenamesArray = apiResponse.data.filenames.map((file: any) => ({
        id: file.id,
        file_name: file.name,
        upload_time: file.upload_time,
        display_name: file.display_name || file.name,
        group_name: file.group_name,
        last_modified_time: file.last_modified_time,
        is_approved: file.is_approved,
      }));
      const approvedFiles = filenamesArray.filter(
        (file: any) => file.is_approved
      );
      const groupFiles = (files: any) => {
        return files.reduce((acc: Record<string, File[]>, file: any) => {
          const groupName = file.group_name || 'Uncategorized'; // Fallback for null or undefined
          if (!acc[groupName]) {
            acc[groupName] = [];
          }
          acc[groupName].push(file);
          return acc;
        }, {});
      };
      const groupedApprovedFiles = groupFiles(approvedFiles);
      setOptions(groupedApprovedFiles);

      // Filter for pending files
      const pendingFilesToAdd = filenamesArray.filter(
        (file: any) => !file.is_approved
      );
      const groupedPendingFiles = groupFiles(pendingFilesToAdd);
      setPendingFiles(groupedPendingFiles);
    } catch (error) {
      console.error('Error fetching options:', error);
      toast({
        title: 'Fetch Error',
        description: 'Failed to fetch files. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        variant: 'subtle',
      });
    } finally {
      setLoadingOptions(false);
    }
  };

  const handleAddTitleClick = () => {
    setShowTitleTextbox(true);
  };

  const handleAddDisplayNameClick = () => {
    setShowTextbox(true);
  };

  const handleAddURLClick = () => {
    setShowURLTextbox(true);
  };

  const handleUpload = async () => {
    if (uploadedFile && selectedPartner && selectedPersona) {
      try {
        setUploading(true);

        const formData = new FormData();
        formData.append('pdf_file', uploadedFile);

        const additionalTitle =
          title && title.length > 0 ? JSON.stringify({ title }) : '';

        const token = await getToken();

        const response = await axios.post(
          `${
            process.env.REACT_APP_LLM_API_URL
          }/upload-file-to-azure?partner_name=${selectedPartnerName}&persona_name=${
            selectedPersona.name
          }&user_id=${profile?.userId}${
            displayName && displayName.length > 0
              ? `&display_name=${displayName}`
              : ''
          }${
            sourceURL && sourceURL.length > 0 ? `&source_url=${sourceURL}` : ''
          }${
            additionalTitle
              ? `&additional_title=${encodeURIComponent(additionalTitle)}`
              : ''
          }`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.data.success === true) {
          setUploadedFile(null);
          toast({
            title: 'Upload Successful',
            description: 'The file has been successfully uploaded.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
          authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
          setSearchTerm('');
          setDisplayName('');
          setShowTextbox(false);
          setSourceURL('');
          setShowURLTextbox(false);
          setTitle('');
          setShowTitleTextbox(false);
        } else {
          toast({
            title: 'Upload Failed',
            description: `${
              response.data?.data
                ? ` ${response.data.data}`
                : ' Something went wrong.'
            }`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast({
          title: 'Upload Failed',
          description: 'Failed to upload the file. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      } finally {
        setUploading(false);
      }
    } else {
      console.log('No file selected or no partner/persona selected.');
    }
  };

  const filteredApprovedFiles = Object.keys(options).reduce(
    (acc: any, group: any) => {
      acc[group] = options[group].filter(
        (file: any) =>
          file.display_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          file.file_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return acc;
    },
    {} as Record<string, any[]>
  );

  const filteredPendingFiles = Object.keys(pendingFiles).reduce(
    (acc: any, group: any) => {
      acc[group] = pendingFiles[group].filter(
        (file: any) =>
          file.display_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          file.file_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return acc;
    },
    {} as Record<string, any[]>
  );

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenDeleteAlert = (fileName: string) => {
    setFileToDelete(fileName);
    setIsDeleteAlertOpen(true);
  };

  const handleCloseDeleteAlert = () => {
    setIsDeleteAlertOpen(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await handleDeleteFile(fileToDelete);
    handleCloseDeleteAlert();
    setIsDeleting(false);
  };

  const handleDeleteFile = async (fileName: string) => {
    try {
      const requestBody: any = {
        partner: selectedPartnerName,
        persona: selectedPersona.name,
        filename: fileName,
        auth: profile?.user?.sub,
      };

      const token = await getToken();
      const response = await axios.delete(
        `${process.env.REACT_APP_LLM_API_URL}/delete-file`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        }
      );

      if (response.data.delete === true) {
        toast({
          title: 'File Deleted',
          description: `The file ${fileName} has been successfully deleted.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
        authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
        setSearchTerm('');

        // fetchPendingFiles(selectedPartner);
      } else {
        toast({
          title: 'Delete Failed',
          description: 'Failed to delete the file. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      toast({
        title: 'Delete Failed',
        description: 'Failed to delete the file. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
        variant: 'subtle',
      });
    }
  };

  const handleFileClick = (fileName: string, displayName: string) => {
    history.push(
      `/file/chunk/${selectedPartnerName}/${selectedPersona.name}/${selectedPersona.id}/${fileName}/${displayName}`
    );
  };

  const handleApprovalFileClick = (
    fileName: string,
    displayName: string,
    documentId: number
  ) => {
    history.push(
      `/file/chunk-tags/${selectedPartnerName}/${selectedPersona.name}/${selectedPersona.id}/${fileName}/${displayName}/${documentId}`
    );
  };

  const handleFileDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setUploadedFile(file);
  };

  const handlePersonaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const selectedPersonaObj = personas.find(
      (persona: any) => persona.id.toString() === selectedId
    );

    if (selectedPersonaObj) {
      setSelectedPersona({
        id: selectedPersonaObj.id,
        name: selectedPersonaObj.name,
      });
      setView('approved');
      authorizeAndGetOptions(selectedPartnerName!, selectedPersonaObj.name);
      setSearchTerm('');

      // Save to localStorage
      localStorage.setItem(
        'selectedPersona',
        JSON.stringify({
          id: selectedPersonaObj.id,
          name: selectedPersonaObj.name,
        })
      );
    }
  };

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setUploadedFile(file);
  };

  const handlePartnerChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const partnerId = e.target.value;
    setSelectedPartner(partnerId);

    const selectedPartnerObj = partners.find(
      (partner) => partner.id === parseInt(partnerId)
    );
    if (selectedPartnerObj) {
      setSelectedPartnerName(selectedPartnerObj.name);
      setLoading(true);
      await fetchPersonas(selectedPartnerObj.id);

      // Save to localStorage
      localStorage.setItem(
        'selectedPartner',
        JSON.stringify({
          id: selectedPartnerObj.id,
          name: selectedPartnerObj.name,
        })
      );
      setLoading(false);
    }
  };

  // Load partner and persona selection from localStorage on mount
  useEffect(() => {
    const storedPartner = localStorage.getItem('selectedPartner');
    const storedPersona = localStorage.getItem('selectedPersona');

    if (storedPartner) {
      const parsedPartner = JSON.parse(storedPartner);
      setSelectedPartner(parsedPartner.id.toString());
      setSelectedPartnerName(parsedPartner.name);
      fetchPersonas(parsedPartner.id).then(() => {
        if (storedPersona) {
          const parsedPersona = JSON.parse(storedPersona);
          setSelectedPersona(parsedPersona);
          authorizeAndGetOptions(parsedPartner.name, parsedPersona.name);
          setSearchTerm('');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!profile?.idToken || !profile?.userId) {
      login();
    }
    if (!profile?.isAdmin) {
      console.log('NOT ADMIN');
    } else {
      fetchPartners();
      console.log('ADMIN');
    }
  }, []);

  useEffect(() => {
    if (selectedPartner && selectedPersona) {
      authorizeAndGetOptions(selectedPartnerName!, selectedPersona.name);
    }
  }, [selectedPartner, selectedPersona]);

  return (
    <Box p={8} maxW="7xl" mx="auto" mt={{ xl: '50px' }}>
      {profile ? (
        !profile.isAdmin ? (
          <Flex justifyContent="center" alignItems="center" minH="200px">
            <Alert
              status="error"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              maxW="400px"
              borderRadius="lg"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={2} fontSize="lg">
                Access Denied
              </AlertTitle>
              <AlertDescription maxWidth="sm" mb={4}>
                You do not have permission to access this page.
              </AlertDescription>
              <Button
                colorScheme="blue"
                onClick={() => (window.location.href = '/')}
              >
                Go to Home
              </Button>
            </Alert>
          </Flex>
        ) : (
          <>
            <FormControl mb={4}>
              <FormLabel htmlFor="partner-select" fontWeight="bold">
                Select Partner
              </FormLabel>
              <Select
                id="partner-select"
                placeholder="Select partner"
                onChange={handlePartnerChange}
                mb={4}
                value={selectedPartner || ''}
              >
                {partners.map((partner) => (
                  <option key={partner.id} value={partner.id}>
                    {partner.display_name || partner.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            {selectedPartner && (
              <FormControl mb={4}>
                <FormLabel htmlFor="persona-select" fontWeight="bold">
                  Select Persona
                </FormLabel>
                <Select
                  id="persona-select"
                  placeholder="Select persona"
                  onChange={handlePersonaChange}
                  mb={4}
                  value={selectedPersona?.id || ''}
                  isDisabled={loading}
                >
                  {personas.map((persona) => (
                    <option key={persona.id} value={persona.id}>
                      {persona.display_name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            {selectedPartner && selectedPersona && (
              <>
                <Tabs
                  index={view === 'approved' ? 0 : 1}
                  onChange={(index) =>
                    setView(index === 0 ? 'approved' : 'pending')
                  }
                >
                  <TabList px="25">
                    <Tab>Approved</Tab>
                    <Tab>Pending Approval</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>{/* Content for Approved Queue */}</TabPanel>
                    <TabPanel>{/* Content for Pending Approval */}</TabPanel>
                  </TabPanels>
                </Tabs>
                <Box px={4} py={2}>
                  <FormControl mb={4}>
                    <FormLabel htmlFor="search" fontWeight="bold">
                      Search Files
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="search"
                        placeholder="Search by file name"
                        value={searchTerm}
                        onChange={handleSearch}
                        color={inputTextColor}
                      />
                      {searchTerm && (
                        <InputRightElement>
                          <IconButton
                            size="sm"
                            aria-label="Clear search"
                            icon={<CloseIcon />}
                            onClick={() => setSearchTerm('')} // Clear the input
                          />
                        </InputRightElement>
                      )}
                    </InputGroup>
                  </FormControl>
                </Box>

                {view === 'approved' && (
                  <>
                    {process.env.REACT_APP_NODE_ENV !== 'production' && (
                      <FormControl mb={4} ml={4}>
                        <FormLabel htmlFor="file-drop" fontWeight="bold">
                          Drop or Select a File
                        </FormLabel>
                        <Box
                          id="file-drop"
                          border="2px dashed"
                          borderRadius="10px"
                          borderColor="gray.300"
                          minH="250px"
                          h="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          onDrop={handleFileDrop}
                          onDragOver={(e) => e.preventDefault()}
                          cursor="pointer"
                        >
                          {uploadedFile ? (
                            <Box p={4}>
                              <Text fontWeight="bold">Selected File:</Text>
                              <Text>{uploadedFile.name}</Text>

                              {!showtitleTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Button
                                    colorScheme="blue"
                                    onClick={handleAddTitleClick}
                                    width="200px"
                                  >
                                    Add Title
                                  </Button>
                                </Flex>
                              )}

                              {showtitleTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Input
                                    placeholder="Enter Title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    width="200px"
                                    color={inputTextColor}
                                  />
                                </Flex>
                              )}

                              {!showTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Button
                                    colorScheme="blue"
                                    onClick={handleAddDisplayNameClick}
                                    width="200px"
                                  >
                                    Add Display Name
                                  </Button>
                                </Flex>
                              )}

                              {showTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Input
                                    placeholder="Enter Display Name"
                                    value={displayName}
                                    onChange={(e) =>
                                      setDisplayName(e.target.value)
                                    }
                                    width="200px"
                                    color={inputTextColor}
                                  />
                                </Flex>
                              )}

                              {!showURLTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Button
                                    colorScheme="blue"
                                    onClick={handleAddURLClick}
                                    width="200px"
                                  >
                                    Add Source URL
                                  </Button>
                                </Flex>
                              )}

                              {showURLTextbox && (
                                <Flex justifyContent="center" mt={3}>
                                  <Input
                                    placeholder="Enter Source URL"
                                    value={sourceURL}
                                    onChange={(e) =>
                                      setSourceURL(e.target.value)
                                    }
                                    width="200px"
                                    color={inputTextColor}
                                  />
                                </Flex>
                              )}

                              <Flex justifyContent="center" mt={3}>
                                {uploading ? ( // Show spinner if uploading
                                  <Spinner />
                                ) : (
                                  <Button
                                    colorScheme="blue"
                                    onClick={handleUpload}
                                  >
                                    Upload File to Index
                                  </Button>
                                )}
                              </Flex>
                            </Box>
                          ) : (
                            <Text>Select or drop a file here</Text>
                          )}
                        </Box>
                        <input
                          type="file"
                          accept=".txt,.pdf,.doc,.docx"
                          onChange={handleFileSelect}
                          style={{ display: 'none' }}
                        />
                      </FormControl>
                    )}

                    {loadingOptions ? (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        minH="200px"
                      >
                        <Spinner size="xl" />
                      </Flex>
                    ) : (
                      <>
                        <Text fontWeight="bold" mb={1} fontSize="lg">
                          Total Approved Files:{' '}
                          {Object.keys(filteredApprovedFiles).reduce(
                            (total, group) =>
                              total + filteredApprovedFiles[group].length,
                            0
                          )}
                        </Text>
                        {Object.keys(filteredApprovedFiles).length ? (
                          Object.keys(filteredApprovedFiles).map(
                            (group, groupIndex) => (
                              <Box key={groupIndex} mb={4}>
                                <Text fontWeight="bold" fontSize="md" mb={2}>
                                  {group}
                                </Text>
                                {filteredApprovedFiles[group].map(
                                  (file: any, fileIndex: any) => (
                                    <Flex
                                      key={fileIndex}
                                      alignItems="center"
                                      justifyContent="space-between"
                                      mb={2}
                                    >
                                      <Box
                                        onClick={() =>
                                          handleFileClick(
                                            file.file_name,
                                            file.display_name || file.file_name
                                          )
                                        }
                                        cursor="pointer"
                                        flex="1"
                                      >
                                        <Text>
                                          {file.display_name || file.file_name}
                                        </Text>
                                      </Box>
                                      <Flex alignItems="center">
                                        {file.last_modified_time && (
                                          <Text
                                            fontSize="sm"
                                            color="gray.500"
                                            mr={4}
                                          >
                                            Uploaded:{' '}
                                            {new Date(
                                              file.last_modified_time
                                            ).toLocaleString()}
                                          </Text>
                                        )}
                                        <Tooltip
                                          label="Edit Name"
                                          aria-label="Edit tooltip"
                                        >
                                          <Box
                                            as={EditIcon}
                                            ml={2}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleEditFileName(file)
                                            }
                                            boxSize={8}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'blue.600' }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Show Details"
                                          aria-label="Show Details tooltip"
                                        >
                                          <Box
                                            as={InfoOutlineIcon}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleFileClick(
                                                file.file_name,
                                                file.display_name ||
                                                  file.file_name
                                              )
                                            }
                                            boxSize={8}
                                            ml={2}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'blue.600' }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Delete"
                                          aria-label="Delete tooltip"
                                          hasArrow
                                          bg="red.500"
                                        >
                                          <Box
                                            as={DeleteIcon}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleOpenDeleteAlert(
                                                file.file_name
                                              )
                                            }
                                            ml={2}
                                            boxSize={8}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'red.600' }}
                                          />
                                        </Tooltip>
                                      </Flex>
                                    </Flex>
                                  )
                                )}
                              </Box>
                            )
                          )
                        ) : (
                          <Text>No approved files available</Text>
                        )}
                      </>
                    )}
                  </>
                )}

                {view === 'pending' && (
                  <>
                    {loadingOptions ? (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        minH="200px"
                      >
                        <Spinner size="xl" />
                      </Flex>
                    ) : (
                      <>
                        <Text fontWeight="bold" mb={1} fontSize="lg">
                          Total Pending Files:{' '}
                          {Object.keys(filteredPendingFiles).reduce(
                            (total, group) =>
                              total + filteredPendingFiles[group].length,
                            0
                          )}
                        </Text>
                        {Object.keys(filteredPendingFiles).length ? (
                          Object.keys(filteredPendingFiles).map(
                            (group, groupIndex) => (
                              <Box key={groupIndex} mb={4}>
                                <Text fontWeight="bold" fontSize="md" mb={2}>
                                  {group}
                                </Text>
                                {filteredPendingFiles[group].map(
                                  (file: any, fileIndex: any) => (
                                    <Flex
                                      key={fileIndex}
                                      alignItems="center"
                                      justifyContent="space-between"
                                      mb={2}
                                    >
                                      <Box
                                        onClick={() =>
                                          handleApprovalFileClick(
                                            file.file_name,
                                            file.display_name || file.file_name,
                                            file.id
                                          )
                                        }
                                        cursor="pointer"
                                        flex="1"
                                      >
                                        <Text>
                                          {file.display_name || file.file_name}
                                        </Text>
                                      </Box>
                                      <Flex alignItems="center">
                                        {file.last_modified_time && (
                                          <Text
                                            fontSize="sm"
                                            color="gray.500"
                                            mr={4}
                                          >
                                            Uploaded:{' '}
                                            {new Date(
                                              file.last_modified_time
                                            ).toLocaleString()}
                                          </Text>
                                        )}
                                        <Tooltip
                                          label="Edit Name"
                                          aria-label="Edit tooltip"
                                        >
                                          <Box
                                            as={EditIcon}
                                            ml={2}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleEditFileName(file)
                                            }
                                            boxSize={8}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'blue.600' }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Show Details"
                                          aria-label="Show Details tooltip"
                                        >
                                          <Box
                                            as={InfoOutlineIcon}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleApprovalFileClick(
                                                file.file_name,
                                                file.display_name ||
                                                  file.file_name,
                                                file.id
                                              )
                                            }
                                            boxSize={8}
                                            ml={2}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'blue.600' }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label="Delete"
                                          aria-label="Delete tooltip"
                                          hasArrow
                                          bg="red.500"
                                        >
                                          <Box
                                            as={DeleteIcon}
                                            color="white"
                                            cursor="pointer"
                                            onClick={() =>
                                              handleOpenDeleteAlert(
                                                file.file_name
                                              )
                                            }
                                            ml={2}
                                            boxSize={8}
                                            borderRadius="50%"
                                            p={2}
                                            _hover={{ bg: 'red.600' }}
                                          />
                                        </Tooltip>
                                      </Flex>
                                    </Flex>
                                  )
                                )}
                              </Box>
                            )
                          )
                        ) : (
                          <Text>No pending files available</Text>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      ) : null}

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleCloseDeleteAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete File
            </AlertDialogHeader>
            <AlertDialogBody>
              {isDeleting ? (
                <Spinner size="md" />
              ) : (
                `Are you sure you want to delete the file ${fileToDelete}?`
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={handleCloseDeleteAlert}
                isDisabled={isDeleting} // Disable the cancel button during deletion
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmDelete}
                ml={3}
                isLoading={isDeleting} // Show loading spinner on the delete button
                isDisabled={isDeleting} // Disable the delete button during deletion
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit File Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              color={inputTextColor}
              onChange={(e) => setEditedFileName(e.target.value)}
              placeholder="Enter new file name"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleSave}
              isLoading={isSaving}
            >
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AdminFileList;
